@keyframes slideIn {
    from { transform: translateX(100%); }
    to { transform: translateX(0); }
  }
  
  @keyframes slideOut {
    from { transform: translateX(0); }
    to { transform: translateX(100%); }
  }
  
  .panel {
    position: fixed;
    top: 0;
    right: 0;
    width: 55%;
    height: 100%;
    background: black;
    border-left: 1px dashed white; /* Existing left border */
    border-top: 1px dashed white;  /* Add top border */
    border-bottom: 1px dashed white; /* Add bottom border */
    z-index: 200;
    background: rgba(0, 0, 0, 0.95); /* Black with 90% opacity */
    transition: transform 0.5s ease-out;
    overflow: auto;
  }

  .bar{
    padding: 2rem;
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: space-between;
    align-items: center;
  }

  .pt-10.pl-6 {
    display: flex;
    flex-direction: column;
    overflow-y: auto; /* Allows vertical scrolling */
    height: 83%; /* Adjust height taking into account padding/margins */
    padding-top: 1.5rem;
    padding: 2rem; /* Adjust based on your spacing needs */
}

img{
    width: auto;
}
  
  .panel.slide-in {
    animation: slideIn 0.5s forwards;
  }
  
  .panel.slide-out {
    animation: slideOut 0.5s forwards;
  }
  
/* Hide or style scrollbar */
.pt-10.pl-6::-webkit-scrollbar {
    display: none;  /* hide scrollbar */
    /* Or use the styling options */
    width: 5px;
}

.pt-10.pl-6::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.pt-10.pl-6::-webkit-scrollbar-thumb {
    background: #888;
}

.pt-10.pl-6::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.pt-10.pl-6 {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

  /* Adjusts the panel for medium screens */
@media (min-width: 768px) and (max-width: 1023px) {
    .panel {
        width: 70%; /* Panel covers 60% of the screen */
    }
}

/* Adjusts the panel for small screens */
@media (max-width: 767px) {
    .panel {
        width: 100%; /* Panel covers the entire screen width */
        border: 1px dashed white; /* Existing left border */
    }

    
}
