/* CursorStyles.css */
.custom-cursor {
    pointer-events: none; /* This ensures the cursor does not block any click events */
    position: fixed;
    width: 10px;  /* Smaller dot size */
    height: 10px;  /* Smaller dot size */
    border-radius: 50%;
    background-color: white; /* White color for the dot */
    transform: translate(-50%, -50%);
    z-index: 9999;  /* Ensure it is above all other elements */
    transition: transform 0.1s;  /* Smooth and quick transition for movements */
  }
  
/* CursorStyles.css updated with a larger, softer halo */
.cursor-halo {
    position: fixed;
    width: 800px;  /* Larger size */
    height: 800px;  /* Larger size */
    pointer-events: none;
    border-radius: 50%;
    background: radial-gradient(circle, rgba(144,238,144,0.15) 0%, rgba(0,128,0,0) 80%);
    transform: translate(-50%, -50%);
    z-index: 0;  /* Set to be behind most elements */
    transition: transform 0.1s;
  }
  
  
  /* Add this to your CursorStyles.css or wherever you maintain global styles */
a {
    cursor: none !important; /* Forcefully ensures that the cursor does not change to pointer */
  }
  