@import 'tailwindcss/tailwind.css';
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fira Code", sans-serif;
  scroll-behavior: smooth;

}

/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@keyframes blink {
  from, to { opacity: 0 }
  50% { opacity: 1 }
}

.home h3:after {
  animation: blink 1s step-end infinite;
}

/* styles.css */
@media (min-width: 768px) {
  .home {
    width: 100%;
  }

  

  /* Add more custom styling as needed */
}

/* CSS: Assuming you have a way to include custom CSS */
@media (max-width: 639px) {
  .custom-pl-small {
    padding-left: 0.5rem; /* This is equivalent to pl-2 */
    padding-top: 3rem;
  }
}

